// Utils
import { isNil, get } from 'lodash'
import { getNavigatorLanguage } from '@/utils'
// Html
const sanitizeHtml = require('sanitize-html')
// Constantes
const { ACTIVATION_STATUS, ACTIVATION_TYPES } = require('@/constants')
// Formato Telefono
import { PhoneNumberUtil, PhoneNumberFormat as PNF } from 'google-libphonenumber'

// Teléfono
const phoneUtil = PhoneNumberUtil.getInstance()
// Fecha
const moment = require('moment')

/**
 * Estado de las activaciones
 *
 * @param {string} id - id del estado
 * @returns {string} - etiqueta del estado
 */
export function activationStatusLabel(id) {
  if (isNil(id)) return id

  return get(ACTIVATION_STATUS, `${id}.label`, null)
}

/**
 * Tipo de activaciones
 *
 * @param {string} id - id del tipo de activación
 * @returns {string} - etiqueta del estado
 */
export function activationTypesLabel(id) {
  if (isNil(id)) return id

  return get(ACTIVATION_TYPES, `${id}.label`, null)
}

/**
 * Formateamos fecha
 *
 * @param {any} date
 * @param {string} format
 * @param {boolean} utc - Universal time
 * @return {String} - fecha formateada
 */
export function formatDate(date, format = 'D MMMM YYYY, h:mm a', utc = true) {
  if (isNil(date)) {
    return date
  }

  const now = new Date()

  let currentDate = date

  if (typeof date.toDate === 'function') {
    currentDate = date.toDate()
  } else if (typeof date === 'number') {
    const mSeconds = utc ? date + now.getTimezoneOffset() * 60000 : date
    currentDate = new Date(mSeconds)
  } else if (date._seconds) {
    const mSeconds = utc
      ? date._seconds * 1000 + now.getTimezoneOffset() * 60000
      : date._seconds * 1000
    currentDate = new Date(mSeconds)
  } else if (date.seconds) {
    const mSeconds = utc
      ? date.seconds * 1000 + now.getTimezoneOffset() * 60000
      : date.seconds * 1000

    currentDate = new Date(mSeconds)
  }

  return moment(currentDate).locale(getNavigatorLanguage()).format(format)
}

/**
 * Formateamos moneda
 *
 * @param {Number} price
 * @param {String} currency
 * @param {Boolean} showZero
 * @return {String} - precio formateado
 */
export function formatPrice(price, currency = 'EUR', showZero = false) {
  const currentCurrency = typeof currency === 'string' ? currency.toUpperCase() : 'EUR'

  return price > 0 || (price === 0 && showZero)
    ? new Intl.NumberFormat(getNavigatorLanguage(), {
        style: 'currency',
        currency: currentCurrency
      }).format(price)
    : '-'
}

/**
 * Formateamos número al estilo del país del navegador
 *
 * @param {Number} value
 * @return {String} - número formateado
 */
export function formatNumber(value) {
  return value ? new Intl.NumberFormat(getNavigatorLanguage()).format(value) : '-'
}

/**
 * Formateamos número de teléfono
 *
 * @param {string} phone
 * @return {string}
 */
export function formatPhoneNumber(value, countryCode = 'ES', format = 'NATIONAL') {
  try {
    const parsedPhoneNumber = phoneUtil.parse(value, countryCode)
    return phoneUtil.format(parsedPhoneNumber, PNF[format])
  } catch (error) {
    return value
  }
}

/**
 * Parseamos el objeto "place" devuelto por GoogleMaps Autocomplete
 * para mostrarlo de un modo más humano
 *
 * @param {Object} place
 * @return {String}
 */
export function formatGoogleAddressObject(place = {}) {
  if (typeof place === 'string') {
    return place
  }

  // Partes de la dirección
  const elements = []

  if (get(place, 'route', null)) {
    const address = `${get(place, 'route')} ${get(place, 'street_number', '')}`
    elements.push(address)
  }

  if (get(place, 'locality', null)) {
    const locality = `${get(place, 'locality')} ${get(place, 'postal_code', '')}`
    elements.push(locality)
  }

  if (get(place, 'administrative_area_level_2', null)) {
    const state = get(place, 'administrative_area_level_2')
    const country = get(place, 'country') ? '(' + get(place, 'country') + ')' : ''
    elements.push(`${state} ${country}`)
  }

  return isNil(elements[0]) ? '' : elements.join(',')
}

/**
 * Limpia contenido de etiquetas HTML no permitidas
 *
 * @param {string} str Input text
 * @param {array} allowedTags allowed tags
 * @param {array} allowedAttributes allowed attributes
 * @param {object} options other options
 * @return {string} Filtered text
 */
export function sanitizeHtmlContent(
  str,
  allowedTags = ['b', 'i', 'em', 'strong', 'u', 's', 'br', 'p'],
  allowedAttributes = {},
  options = {}
) {
  if (isNil(str)) return ''

  return sanitizeHtml(str, {
    allowedTags,
    allowedAttributes,
    ...options
  })
}
